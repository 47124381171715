import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = BasicProps

const ListItem: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, children } = props

  return (
    <li id={id} className={clsx(className)}>
      {children}
    </li>
  )
}

export default ListItem
