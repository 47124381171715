import { FC, PropsWithChildren, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = {
  href: string
  blank?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
} & BasicProps

const Anchor: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, href, blank, onClick, children } = props

  return (
    <a
      id={id}
      href={href}
      target={blank ? '_blank' : undefined}
      className={clsx('break-all', 'cursor-pointer', 'hover:opacity-75', className)}
      rel='noreferrer'
      onClick={onClick}
    >
      {children}
    </a>
  )
}

Anchor.defaultProps = {
  blank: true,
  onClick: undefined,
}

export default Anchor
