import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = { valiant: 1 | 2 | 3 | 4 | 5 | 6 } & BasicProps

const Headline: FC<PropsWithChildren<Props>> = (props) => {
  const { valiant, id, className, children } = props
  switch (valiant) {
    case 1:
      return (
        <h1 id={id} className={clsx(className)}>
          {children}
        </h1>
      )
    case 2:
      return (
        <h2 id={id} className={clsx(className)}>
          {children}
        </h2>
      )
    case 3:
      return (
        <h3 id={id} className={clsx(className)}>
          {children}
        </h3>
      )
    case 4:
      return (
        <h4 id={id} className={clsx(className)}>
          {children}
        </h4>
      )
    case 5:
      return (
        <h5 id={id} className={clsx(className)}>
          {children}
        </h5>
      )
    case 6:
      return (
        <h6 id={id} className={clsx(className)}>
          {children}
        </h6>
      )
    default:
      return (
        <p id={id} className={clsx(className)}>
          {children}
        </p>
      )
  }
}

export default Headline
