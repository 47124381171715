/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropsWithChildren, forwardRef, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = { onClick?: MouseEventHandler<HTMLDivElement> } & BasicProps

const Division = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const { id, onClick, className, children } = props

  return (
    <div id={id} ref={ref} className={clsx(className)} onClick={onClick}>
      {children}
    </div>
  )
})

Division.defaultProps = {
  onClick: undefined,
}

export default Division
