import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = BasicProps

const Main: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, children } = props

  return (
    <main id={id} className={clsx(className)}>
      {children}
    </main>
  )
}

export default Main
