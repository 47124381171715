import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = BasicProps

const UnorderedList: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, children } = props

  return (
    <ul id={id} className={clsx(className)}>
      {children}
    </ul>
  )
}

export default UnorderedList
