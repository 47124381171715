import { FC } from 'react'
import Head from 'components/atoms/Head'

type Props = { title?: string; description?: string; keywords?: string[] }

const defaultTitle = 'NEXUS Midy - Personal training exclusively for women -'
const defaultDescription =
  '京都府京田辺市（同志社山手・三山木エリア）の女性専用パーソナルトレーニングスタジオ NEXUS Midy (ネクサス・ミディ) のウェブサイトです。'
const defaultKeywords = [
  'パーソナルトレーニング',
  '運動',
  'ヨガ',
  'ピラティス',
  '栄養',
  '同志社山手',
  '三山木',
  '京田辺市',
  'ダイエット',
  '体質',
  '姿勢',
]

const PageHead: FC<Props> = (props) => {
  const { title, description, keywords } = props

  return (
    <Head
      defaultTitle={defaultTitle}
      title={title}
      meta={[
        { charSet: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'manifest', content: `${process.env.PUBLIC_URL}/manifest.json` },
        { name: 'description', content: description || defaultDescription },
        { name: 'keywords', content: (keywords || defaultKeywords).join(',') },
        // { property: 'og:title', content: title || defaultTitle },
        // { property: 'og:type', content: 'website' },
        // { property: 'og:url', content: window.location.href },
        // { property: 'og:description', content: description },
        // { property: 'og:image', content: image || `https://midy.sports-nexus.jp/opg.jpeg` },
        // { name: 'twitter:card', content: 'summary_large_image' },
        // { name: 'twitter:site', content: '@SportsNexusWeb' },
        // { property: 'fb:app_id', content: '408987256212082' },
      ]}
      link={[
        // { rel: 'icon', href: `${process.env.PUBLIC_URL}/favicon.ico` },
        { rel: 'apple-touch-icon', href: `${process.env.PUBLIC_URL}/apple-touch-icon.png` },
        { rel: 'icon', sizes: '512x512', href: `${process.env.PUBLIC_URL}/icon-512x512.png` },
        { rel: 'icon', sizes: '192x192', href: `${process.env.PUBLIC_URL}/icon-192x192.png` },
        { rel: 'manifest', href: `${process.env.PUBLIC_URL}/manifest.json` },
      ]}
    />
  )
}

PageHead.defaultProps = {
  title: undefined,
  description: undefined,
  keywords: undefined,
}

export default PageHead
