import { FC } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = BasicProps

const HorizontalRule: FC<Props> = (props) => {
  const { id, className } = props

  return <hr id={id} className={clsx(className)} />
}

export default HorizontalRule
