import { FC } from 'react'
import PageHead from 'components/organisms/Head'
import Main from 'components/atoms/Main'
import Division from 'components/atoms/Division'
import clsx from 'clsx'
import Paragraph from 'components/atoms/Paragraph'
import Anchor from 'components/atoms/Anchor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import UnorderedList from 'components/atoms/UnorderedList'
import ListItem from 'components/atoms/ListItem'
import Headline from 'components/atoms/Headline'
import HorizontalRule from 'components/atoms/HorizontalRule'
import Footer from 'components/atoms/Footer'
import Copyright from 'components/molecules/Copyright'
import Break from 'components/atoms/Break'

const TopPage: FC = () => (
  <>
    <PageHead
      title='NEXUS Midy | 女性専用パーソナルトレーニングスタジオ NEXUS Midy (ネクサス・ミディ)'
      description='京都府京田辺市（同志社山手・三山木エリア）の女性専用パーソナルトレーニングスタジオ NEXUS Midy (ネクサス・ミディ) のウェブサイトです。'
    />
    <Division
      className={clsx(
        'bg-root',
        'bg-cover',
        'bg-repeat-y',
        'font-serif',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'min-h-screen',
        'text-neutral-600'
      )}
    >
      <Main className={clsx('flex', 'items-center', 'justify-center', 'grow', 'p-4', 'max-w-3xl')}>
        <Division className={clsx('flex', 'flex-col', 'items-center', 'justify-center', 'gap-6')}>
          <Headline valiant={2} className={clsx('text-xl')}>
            お知らせ
          </Headline>
          <HorizontalRule className={clsx('w-full', 'h-1')} />
          <Division className={clsx('flex', 'flex-col', 'gap-2')}>
            <Paragraph className={clsx('leading-relaxed', 'tracking-wider')}>
              この度、NEXUS Midy 同志社山手店は 8 月 8 日をもって営業を終了し、
              <Break className={clsx('hidden', 'md:inline')} />8 月 17 日より{' '}
              <Anchor
                href='https://www.sports-nexus.jp/'
                blank
                className={clsx('border-b', 'border-solid', 'border-neutral-500')}
              >
                Personal training studio NEXUS 松井山手店
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className={clsx('text-xs', 'text-neutral-500', 'ml-1')}
                />
              </Anchor>{' '}
              と統合することとなりました。
            </Paragraph>
            <Paragraph className={clsx('leading-relaxed', 'tracking-wider')}>
              これまで当店を利用いただきましたお客様に改めて感謝申し上げます。
            </Paragraph>
            <Paragraph className={clsx('leading-relaxed', 'tracking-wider')}>
              引き続き NEXUS 松井山手店 にて、以下のセッションを提供しております。
              <UnorderedList className='my-2 list-disc pl-6'>
                <ListItem>パーソナルピラティス</ListItem>
                <ListItem>パーソナルヨガ</ListItem>
                <ListItem>理学療法士によるパーソナルトレーニング</ListItem>
              </UnorderedList>
              今後とも変わらぬご愛顧のほどよろしくお願いいたします。
            </Paragraph>
          </Division>
        </Division>
      </Main>
      <Footer>
        <Copyright>© 2018 NEXUS, Inc.</Copyright>
      </Footer>
    </Division>
  </>
)

export default TopPage
