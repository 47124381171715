import { FC, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import TopPage from 'components/pages/Top'
import { HelmetProvider } from 'react-helmet-async'
import { scroller } from 'react-scroll'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const scrollOffset = -72

const ScrollToTop: FC = () => {
  const { pathname, hash } = useLocation()
  // console.log(`change location pathname : ${pathname}, hash: ${hash}`)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (hash) {
      // console.log(`has hash : ${hash}`)
      scroller.scrollTo(hash.replace('#', ''), {
        duration: 2500,
        delay: 500,
        smooth: true,
        offset: scrollOffset,
      })
    }
  }, [pathname, hash])

  return null
}

const App: FC = () => (
  <HelmetProvider>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='*' element={<TopPage />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
)

export default App
