import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import Paragraph from 'components/atoms/Paragraph'
import { BasicProps } from 'components/types'

type Props = BasicProps

const Copyright: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, children } = props

  return (
    <Paragraph id={id} className={clsx('text-center', 'text-sm', className)}>
      {children}
    </Paragraph>
  )
}

export default Copyright
