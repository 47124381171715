import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { BasicProps } from 'components/types'

type Props = BasicProps

const Footer: FC<PropsWithChildren<Props>> = (props) => {
  const { id, className, children } = props

  return (
    <footer id={id} className={clsx('px-2', 'pt-8', 'pb-1', className)}>
      {children}
    </footer>
  )
}

export default Footer
