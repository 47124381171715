import { FC, PropsWithChildren, DetailedHTMLProps, MetaHTMLAttributes, LinkHTMLAttributes } from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  defaultTitle: string
  title?: string
  meta?: DetailedHTMLProps<MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[]
  link?: DetailedHTMLProps<LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[]
  script?: unknown[]
}

const Head: FC<PropsWithChildren<Props>> = (props) => {
  const { defaultTitle, title, meta, link, script, children } = props

  return (
    <Helmet defaultTitle={defaultTitle} title={title} meta={meta} link={link} script={script}>
      {children}
    </Helmet>
  )
}

Head.defaultProps = {
  title: undefined,
  meta: undefined,
  link: undefined,
  script: undefined,
}

export default Head
